
import { defineComponent, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { useNotification } from '@/composables'
import userSettingsRepositories from '@/repositories/userSettingsRepositories'
import { ICustomer } from '@/interfaces/order'

export default defineComponent({
  props: {
    customer: {
      type: Object,
      default: () => {
        return {}
      },
    },
    alowEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const showModalEditCustomer = ref(false)
    const { editCustomer } = useOrderRepositories()
    const { params } = useRoute()
    const state = reactive<{ customer: ICustomer }>({
      customer: {
        fullName: '',
        address1: '',
        address2: '',
        city: '',
        province: '',
        phone: '',
        email: '',
        country: '',
        countryCode: '',
        zipCode: '',
        errorMsg: '',
      },
    })
    const formEditCustomer = ref()
    const { error, success } = useNotification()
    const onEditCustomer = () => {
      if (!props.alowEdit) {
        close()
        return
      }
      formEditCustomer.value.validate(async (valid: any) => {
        if (valid) {
          const { status, data } = await editCustomer(
            params.id as string,
            state.customer
          )
          if (status === 201) {
            success('Update success')
            close()
            context.emit('updateSuccess')
          } else {
            error(data.message)
          }
        } else {
          error('error submit!!')
          return false
        }
      })
    }

    const toggleModal = (customer: ICustomer) => {
      state.customer = customer
      showModalEditCustomer.value = !showModalEditCustomer.value
    }

    const close = () => {
      showModalEditCustomer.value = false
      formEditCustomer.value?.resetFields()
    }

    const { getContactSetting } = userSettingsRepositories()
    const contactUrl = ref<any>()
    const getContactSettings = async () => {
      contactUrl.value = (await getContactSetting()) as any
    }

    const onClickContactSupport = async () => {
      if (!contactUrl.value) {
        await getContactSettings()
      }
      window.open(contactUrl.value)
    }

    return {
      state,
      formEditCustomer,
      onEditCustomer,
      showModalEditCustomer,
      toggleModal,
      close,
      contactUrl,
      onClickContactSupport,
    }
  },
})
