
import { defineComponent, ref } from 'vue'
import { copyToClipboard } from '@/utils/string'
import {
  getOrderStatusColor,
  getAddressStatusColor,
  validateOrderAction,
  getWarningContent,
} from '@/utils/orderUtils'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { useNotification } from '@/composables'
import { UserTypes } from '@/utils/types'
import { addressTitleMap, ADDRESS_STATUS } from '@/utils/constants'
import { formatTime } from '@/utils/dateTime'
import { CopyDocument, ArrowDown, Warning } from '@element-plus/icons'
import RequestSellerDialog from '@/pages/agency/orders/components/RequestSellerDialog.vue'

export default defineComponent({
  components: { RequestSellerDialog, CopyDocument, ArrowDown, Warning },
  props: {
    state: {
      type: Object,
      required: true,
    },
    userInfo: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      cancelOrders,
      verifyAddressOrders,
      holdOrders,
      unHoldOrders,
      syncedTrackToStore,
    } = useOrderRepositories()

    const {
      validateHold,
      validateUnHold,
      validateSellerCancel,
      validateVerifyAddress,
      validateUpdateSyncedTrackToStore,
    } = validateOrderAction()

    const { error, success } = useNotification()

    const refresh = () => {
      emit('refresh')
    }
    const copyToClipboardOrderName = (text: string) => {
      copyToClipboard(text)
      success('Copy to clipboard')
    }

    const holdOrder = async (ids: any[]) => {
      try {
        const res = (await holdOrders({
          ids: ids,
        })) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Hold order success!`)
          refresh()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const unHoldOrder = async (ids: any[]) => {
      try {
        const res = (await unHoldOrders({
          ids: ids,
        })) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Unhold order success!`)
          refresh()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const cancelOrder = async (ids: any[]) => {
      try {
        const res = (await cancelOrders({
          ids: ids,
        })) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Cancel order success!`)
          refresh()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const verifyAddressOrder = async (ids: any[]) => {
      try {
        const res = (await verifyAddressOrders({
          ids: ids,
        })) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Verify Address order success!`)
          refresh()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const syncedTrackToStoreOrder = async (ids: any[]) => {
      try {
        const res = (await syncedTrackToStore(ids)) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Update order success!`)
          refresh()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const requestSellerDialog = ref<InstanceType<typeof RequestSellerDialog>>()
    const onClickRequestSeller = (order: any) => {
      requestSellerDialog.value?.toggle(order)
    }

    return {
      getOrderStatusColor,
      getAddressStatusColor,
      getWarningContent,
      validateHold,
      validateUnHold,
      validateSellerCancel,
      validateVerifyAddress,
      validateUpdateSyncedTrackToStore,
      addressTitleMap,
      ADDRESS_STATUS,
      UserTypes,
      formatTime,
      refresh,
      copyToClipboardOrderName,
      holdOrder,
      unHoldOrder,
      cancelOrder,
      verifyAddressOrder,
      syncedTrackToStoreOrder,
      requestSellerDialog,
      onClickRequestSeller,
    }
  },
})
