
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useRoute, useRouter } from 'vue-router'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { ICustomer } from '@/interfaces/order'
import { imageUrl } from '@/utils/image'
import ChangeHistoryContent from '@/components/orders/ChangeHistoryContent.vue'
import UploadDesign from './components/UploadDesign.vue'
import ChangeCatalogCodeDialog from '@/components/common/ChangeCatalogCode.vue'
import { useNotification } from '@/composables'
import { formatterDollars } from '@/utils/formatter'
import { DESIGN_STATUS, ORDER_STATUS } from '@/utils/constants'
import UploadImage from './components/UploadImage.vue'
import {
  countDesignQtyOrderItem,
  changeHistory,
  CHANGE_ORDER_HISTORY,
  validateOrderStatusReCalculate,
} from '@/utils/orderUtils'
import { validateEditArtworkCheck } from '@/utils/orderUtils'
import CustomDialog from '@/components/orders/CustomDialog.vue'
import useMappingReponsitories from '@/repositories/useMappingReponsitories'
import RequestSellerDialog from '@/pages/agency/orders/components/RequestSellerDialog.vue'
import OrderFeesCard from '@/pages/agency/orders/components/Cards/OrderFeesCard.vue'
import OrderProfitCard from '@/pages/agency/orders/components/Cards/OrderProfitCard.vue'
import { ElMessageBox } from 'element-plus'
import OrderInfo from './components/Detail/OrderInfo.vue'
import TitleInfo from './components/Detail/TitleInfo.vue'

export default defineComponent({
  components: {
    sectionLayoutContent,
    UploadDesign,
    UploadImage,
    CustomDialog,
    ChangeCatalogCodeDialog,
    ChangeHistoryContent,
    RequestSellerDialog,
    OrderFeesCard,
    OrderProfitCard,
    OrderInfo,
    TitleInfo,
  },
  setup() {
    const breadcrumbs = [
      {
        text: `Orders`,
        link: 'seller.orders',
        params: null,
      },
      {
        text: `Detail`,
        link: '',
        params: null,
      },
    ]
    const route = useRoute()
    const router = useRouter()
    const data = reactive<{
      customer: ICustomer
      rules: any
      isLoading: boolean
      variant: any
    }>({
      customer: {
        fullName: '',
        address1: '',
        address2: '',
        city: '',
        province: '',
        phone: '',
        email: '',
        country: '',
        countryCode: '',
        zipCode: '',
        errorMsg: '',
      },
      rules: {
        fullName: [
          {
            required: true,
            message: 'Please input Name',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 255,
            message: 'Length should be 3 to 255',
            trigger: 'blur',
          },
        ],
        address1: [
          {
            required: true,
            message: 'Please input Address',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 255,
            message: 'Length should be 3 to 255',
            trigger: 'blur',
          },
        ],
        city: [
          {
            required: true,
            message: 'Please input City',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 255,
            message: 'Length should be 3 to 255',
            trigger: 'blur',
          },
        ],
        zipCode: [
          {
            required: true,
            message: 'Please input Zipcode',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 60,
            message: 'Length should be 3 to 255',
            trigger: 'blur',
          },
        ],
      },
      isLoading: true,
      variant: {
        variantTitle: '',
        artwork1: '',
        artwork2: '',
        artwork3: '',
        artwork4: '',
      },
    })

    const orderCannotUpdateMsg = 'This order can not update.'

    const {
      getOrder,
      state,
      editOrder,
      updateOrderItemCatalogCode,
      updateOrderChangeHistory,
      calcOrderProfit,
      calcOrderFulfillmentPrice,
      updateOrderItemArtwork,
    } = useOrderRepositories()
    const { onUploadDesignOrderItem, onSelectCatalogCodeOrderItem } =
      useMappingReponsitories()
    const artworkSpaces = ref(['artwork1', 'artwork2', 'artwork3', 'artwork4'])

    const content = computed(() => {
      return { breadcrumbs }
    })

    const { error, success } = useNotification()

    const validateDesignQtyOrderItem = (item: any) => {
      if (item) {
        const designQty = item.designQty ? item.designQty : 0
        const countDesignOfItem = countDesignQtyOrderItem(item)
        return designQty === countDesignOfItem
      }
    }

    const { ROLE, ACTION_NAME } = CHANGE_ORDER_HISTORY()

    const userInfo = JSON.parse(localStorage?.getItem('info') || '')

    const validOrderBeforeUpdate = async () => {
      const currentOrderStatus = state.order.status
      return (
        currentOrderStatus &&
        (currentOrderStatus === ORDER_STATUS.UNFULFILLED ||
          currentOrderStatus === ORDER_STATUS.IN_REVIEW ||
          currentOrderStatus === ORDER_STATUS.IN_PRODUCTION ||
          currentOrderStatus === ORDER_STATUS.IN_TRANSIT ||
          currentOrderStatus === ORDER_STATUS.HOLD)
      )
    }

    // hàm được gọi sau khi upload thêm design của item lên
    const uploaded = async (uploaded: any) => {
      console.log('uploaded')
      updateOrderAfterChangeDesign(uploaded, ACTION_NAME.UPLOAD_DESIGN)
    }

    const updateOrderAfterChangeDesign = async (
      uploaded: any,
      actionName: string
    ) => {
      const isValidOrderForUpdate = await validOrderBeforeUpdate()
      if (!isValidOrderForUpdate) {
        error(orderCannotUpdateMsg)
        return
      }

      const orderItemId = state.order.items[uploaded.index]?._id?.toString()
      const orderId = state.order?._id?.toString()

      // phuc vu ghi log
      const itemIndex = uploaded.index
      const oldDesignPath = state.order.items[uploaded.index][uploaded.artwork]
      const newDesignPath = imageUrl(uploaded.path)
      if (oldDesignPath === newDesignPath) {
        success('No changed!')
        return
      }

      const dataChange = {
        actionName: actionName,
        itemId: state.order.items[itemIndex]?._id,
        log: {
          designName: uploaded.artwork,
          itemIndex: itemIndex + 1,
          oldDesignPath: oldDesignPath,
          newDesignPath: newDesignPath,
        },
        createdDate: new Date(),
        seller: userInfo
          ? {
              id: userInfo.id,
              agency: userInfo.agency,
              email: userInfo.email,
              sellerTeam: {
                id: userInfo?.sellerTeam?.id,
              },
            }
          : {},
      }
      state.order = changeHistory(state.order, ROLE.SELLER, dataChange)

      const newDesignUrl = imageUrl(uploaded.path)
      // check số lượng design của item sau khi upload thêm desgin lên
      const checkDesignQty = validateDesignQtyOrderItem(
        state.order.items[uploaded.index]
      )
      const designStatus = checkDesignQty
        ? DESIGN_STATUS.MAPPED_UPLOADED
        : DESIGN_STATUS.NONE

      const body = {
        orderItemId,
        artworkName: uploaded.artwork,
        designStatus,
        newDesignUrl,
      }
      const { status } = await updateOrderItemArtwork(body)

      if (status === 200) {
        success('Success')

        // item không phải custom thì sẽ cập nhật vào product để sử dụng lại Design
        if (state.order.items[uploaded.index]?.personalized !== true) {
          updateDesignToProduct(
            state.order.items[uploaded.index]?.productId,
            uploaded.artwork,
            newDesignPath,
            state.order.items[uploaded.index]?.variantTitle,
            uploaded?.isApplyForAllVariants
          )
        }

        // update lịch sử chỉnh sửa
        await updateOrderChangeHistory({
          orderId,
          changeHistory: state.order.changeHistory,
        })
        fetchRecord()
      } else {
        error('Failed')
      }
    }

    const updateDesignToProduct = async (
      productId: string,
      artworkName: string,
      artworkUrl: string,
      variantTitle: string,
      isApplyForAllVariants: boolean
    ) => {
      if (!productId || !artworkName || !variantTitle) {
        return
      }
      const dto = {
        name: artworkName,
        url: imageUrl(artworkUrl),
        variantTitle,
        isApplyForAllVariants,
      }
      await onUploadDesignOrderItem(productId, dto)
    }

    const updateProductCodeToProduct = async (productId: string, data: any) => {
      if (!productId || !data) {
        return
      }
      const dto = {
        variantTitle: data.variantTitle,
        catalogCode: data.catalogCode,
        catalog: data.catalog,
        catalogVariant: data.catalogVariant,
        designQty: data.designQty,
      }
      await onSelectCatalogCodeOrderItem(productId, dto)
    }

    const fetchRecord = async () => {
      data.isLoading = true
      let order = await getOrder(route.params.id)
      if (order) {
        data.customer = {
          fullName: order.fullName,
          address1: order.address1,
          address2: order.address2,
          city: order.city,
          province: order.province,
          phone: order.phone,
          email: order.email,
          country: order.country,
          countryCode: order.countryCode,
          zipCode: order.zipCode,
          errorMsg:
            order.addressValid === 0 && order?.errorMsg ? order.errorMsg : '',
        }
      }
      data.isLoading = false
    }

    onMounted(async () => {
      fetchRecord()
    })

    // hàm được gọi sau khi upload change design của item lên
    const changeArtwork = async (uploaded: any) => {
      console.log('changeArtwork')
      updateOrderAfterChangeDesign(uploaded, ACTION_NAME.CHANGE_DESIGN)
    }

    const totalItems = computed(() => {
      if (state.order && state.order.items) {
        const total = state.order.items.reduce((t: number, item: any) => {
          return t + item.quantity
        }, 0)
        return total
      }
      return 0
    })

    const callbackImageSrc = async () => {
      console.log('callbackImageSrc')
    }

    const changeImageSrc = async (uploaded: any) => {
      console.log('changeImageSrc')
      const isValidOrderForUpdate = await validOrderBeforeUpdate()
      if (!isValidOrderForUpdate) {
        error(orderCannotUpdateMsg)
        return
      }

      const index = uploaded.index
      const path = uploaded.path
      const artwork = uploaded.artwork
      if (artwork === 'imageSrc' && path?.length > 0) {
        const oldPreviewPath = state.order.items[index][artwork]
        // Object.assign(state.order.items[index], { imageSrc: path })
        state.order.items[index][artwork] = imageUrl(path)

        // phuc vu ghi log
        const dataChange = {
          actionName: ACTION_NAME.CHANGE_PREVIEW,
          itemId: state.order.items[index]._id,
          log: {
            itemIndex: index + 1,
            oldPreviewPath: oldPreviewPath,
            newPreviewPath: imageUrl(path),
          },
          createdDate: new Date(),
          seller: userInfo
            ? {
                id: userInfo.id,
                agency: userInfo.agency,
                email: userInfo.email,
                sellerTeam: {
                  id: userInfo?.sellerTeam?.id,
                },
              }
            : {},
        }
        state.order = changeHistory(state.order, ROLE.SELLER, dataChange)

        const { status } = await editOrder(state.order._id, state.order)
        if (status === 200) {
          success(`Update preview success!`)
          fetchRecord()
        } else {
          error(`Update preview fail`)
        }
      } else {
        error('Error when update preview!')
      }
    }

    const callbackDesign = async (
      setData: any,
      files: string[],
      data: any,
      action: any,
      artwork: any,
      itemIndex: any
    ) => {
      console.log('callbackDesign')
      const isValidOrderForUpdate = await validOrderBeforeUpdate()
      if (!isValidOrderForUpdate) {
        error(orderCannotUpdateMsg)
        return
      }

      let message = ''
      if (action === 'del') {
        state.order.items[itemIndex][artwork] = ''
        message = 'Deleted artwork'
      } else if (action === 'add') {
        state.order.items[itemIndex][artwork] = imageUrl(files[0])
        message = 'Uploaded artwork'
      }

      // check số lượng design của item sau khi upload thêm desgin lên
      const checkDesignQty = validateDesignQtyOrderItem(
        state.order.items[itemIndex]
      )
      state.order.items[itemIndex].designStatus = checkDesignQty
        ? DESIGN_STATUS.MAPPED_UPLOADED
        : DESIGN_STATUS.NONE

      const { status } = await editOrder(state.order._id, state.order)
      if (status === 200) {
        success(`${message} success`)
        fetchRecord()
      } else {
        error(`${message} fail`)
      }
    }
    const customDialog = ref<InstanceType<typeof CustomDialog>>()
    const onViewDetailCustom = (custom: any) => {
      customDialog.value?.toggle(custom)
    }

    const changeCatalogCodeDialog =
      ref<InstanceType<typeof ChangeCatalogCodeDialog>>()
    const onChangeCatalogCode = (item: any) => {
      if (!item) {
        error('Not found item!')
        return
      }
      changeCatalogCodeDialog.value?.toggle(item)
    }

    const statusAlowChangeVariantCode = [
      ORDER_STATUS.UNFULFILLED,
      ORDER_STATUS.HOLD,
      ORDER_STATUS.IN_REVIEW,
    ]

    const calculatingOrderBasecost = ref(false)

    const onAfterChangeVariantCode = async (data: any) => {
      try {
        calculatingOrderBasecost.value = true
        const newCatalogCode = data.variantCode
        const designQty = data.designQty
        const itemId = data.itemId

        if (newCatalogCode?.length === 0) {
          error('Not found new catalog code!')
          return
        }
        if (itemId?.length === 0) {
          error('itemId is null!')
          return
        }
        if (!statusAlowChangeVariantCode.includes(state.order.status)) {
          error('Can not update this order!')
          return
        }
        const itemNeedUpdateIndex = state.order.items.findIndex(
          (item: { _id: any }) => item._id === itemId
        ) as any
        if (itemNeedUpdateIndex !== -1) {
          // phuc vu ghi log
          const oldCatalogCode =
            state.order.items[itemNeedUpdateIndex].catalogCode
          const oldDesignQty = state.order.items[itemNeedUpdateIndex].designQty

          state.order.items[itemNeedUpdateIndex].catalogCode = newCatalogCode
          state.order.items[itemNeedUpdateIndex].designQty = designQty

          const designStatus = validateDesignQtyOrderItem(
            state.order.items[itemNeedUpdateIndex]
          )
            ? DESIGN_STATUS.MAPPED_UPLOADED
            : DESIGN_STATUS.NONE

          state.order.items[itemNeedUpdateIndex].designStatus = designStatus

          // phuc vu ghi log
          const dataChange = {
            actionName: ACTION_NAME.CHANGE_CATALOG_CODE,
            itemId: state.order.items[itemNeedUpdateIndex]._id,
            log: {
              oldCatalogCode: oldCatalogCode,
              oldDesignQty: oldDesignQty,
              newCatalogCode: newCatalogCode,
              newDesignQty: designQty,
              newDesignStatus: designStatus,
            },
            createdDate: new Date(),
            seller: userInfo
              ? {
                  id: userInfo.id,
                  agency: userInfo.agency,
                  email: userInfo.email,
                  sellerTeam: {
                    id: userInfo?.sellerTeam?.id,
                  },
                }
              : {},
          }
          state.order = changeHistory(state.order, ROLE.SELLER, dataChange)

          // đổi sang edit mỗi item - catalogCode
          let orderItemId = state.order.items[itemNeedUpdateIndex]._id
          let catalogCode = newCatalogCode

          const orderId = state.order?._id?.toString()
          // Chỉ update với những đơn từ InReview trở đi, ko tính Cancel, Reject
          if (validateOrderStatusReCalculate(state.order?.status)) {
            const body = {
              orderId,
              orderItemId,
              catalogCode,
            }

            /**
             * Khi tính toán lại basecost:
             * 1. thay catalogCode mới vào, tính lại giá base
             * 2. hoàn tiền hoặc trừ tiền
             *    2.1. Nếu hoàn tiền thì tạo invoice hoàn tiền
             *    2.2. Trừ tiền
             *      2.2.1: Trừ tiền thành công -> tạo invoice
             *      2.2.2: Trừ tiền thất bại - trả lại kết quả -> thông báo cho seller
             */
            const resCalc: any = await calcOrderFulfillmentPrice(body)

            if (resCalc?.status === 200) {
              const resData = resCalc.data
              const updateSellerBalance = resData.updateSellerBalance
              const orderPriceChanged = resData.orderPriceChanged

              // Failed: xảy ra khi cần trừ thêm tiền mà seller lại ko đủ balance
              let message = '' //updateSellerBalance?.message
              let balanceNeedTopup = formatterDollars(
                Math.abs(updateSellerBalance?.balance?.amount ?? 0)
              )
              if (updateSellerBalance?.status === 'Success') {
                const invoiceType = orderPriceChanged > 0 ? 'credit' : 'debit'
                if (updateSellerBalance?.balance?.amount !== 0) {
                  message = `Your balance will ${invoiceType} ${balanceNeedTopup} for changed code.`
                  warningTopupWhenChangeCatalogCode(message)
                }
              } else if (updateSellerBalance?.status === 'Failed') {
                message = `Please topup ${balanceNeedTopup} to change this code.`
                warningTopupWhenChangeCatalogCode(message)
                return
              }
            } else {
              error(`Recalculate price of order error!`)
            }
          }
          const bodyUpdateCatalogCode = {
            orderItemId,
            catalogCode,
            designQty,
            catalogId: data?.selectedCatalog?.catalogId,
            supplierCode: data?.selectedCatalog?.supplierCode,
            catalogVariantId: data?.selectedCatalog?.catalogVariantId,
          }
          const { status } = await updateOrderItemCatalogCode(
            bodyUpdateCatalogCode
          )

          if (status === 200 || status === 201) {
            success('Edit order success!')

            // update lịch sử chỉnh sửa
            await updateOrderChangeHistory({
              orderId,
              changeHistory: state.order.changeHistory,
            })

            // update vào variant của product
            let updateCatalogCodeDto = {
              variantTitle:
                state.order.items[itemNeedUpdateIndex]?.variantTitle,
              catalogCode: newCatalogCode,
              catalog: data?.selectedCatalog,
              catalogVariant: data?.selectedVariant,
              designQty: data?.designQty,
            }
            updateProductCodeToProduct(
              state.order.items[itemNeedUpdateIndex]?.productId,
              updateCatalogCodeDto
            )

            // tính lại profit
            if (
              state.order?.syncProfit !== 0 &&
              validateOrderStatusReCalculate(state.order?.status)
            ) {
              await calcOrderProfit(orderId)
            }

            // load lại order
            fetchRecord()
          } else {
            error(`Change catalog code fail`)
          }
        }
      } catch (e) {
        console.log('onAfterChangeVariantCode ~ e:', e)
      }
      calculatingOrderBasecost.value = false
    }

    const warningTopupWhenChangeCatalogCode = (message: string) => {
      ElMessageBox.alert(message, 'Warning topup', {
        confirmButtonText: 'Confirm',
      })
    }

    return {
      onAfterChangeVariantCode,
      content,
      callbackDesign,
      state,
      totalItems,
      data,
      getOrder,
      uploaded,
      imageUrl,
      fetchRecord,
      artworkSpaces,
      formatterDollars,
      validateEditArtworkCheck,
      changeArtwork,
      validateDesignQtyOrderItem,
      DESIGN_STATUS,
      countDesignQtyOrderItem,
      router,
      callbackImageSrc,
      changeImageSrc,
      onViewDetailCustom,
      customDialog,
      userInfo,
      onChangeCatalogCode,
      changeCatalogCodeDialog,
      ORDER_STATUS,
      changeHistory,
      validOrderBeforeUpdate,
      orderCannotUpdateMsg,
      updateDesignToProduct,
      onUploadDesignOrderItem,
      onSelectCatalogCodeOrderItem,
      updateOrderAfterChangeDesign,
      updateProductCodeToProduct,
      statusAlowChangeVariantCode,
      calculatingOrderBasecost,
      warningTopupWhenChangeCatalogCode,
    }
  },
})
