
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'

import { useNotification } from '@/composables'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { validateOrderAction } from '@/utils/orderUtils'

import RequestSellerDialog from '@/pages/agency/orders/components/RequestSellerDialog.vue'
import CustomerDialog from './../Customer.vue'
import Timeline from '@/components/orders/Timeline.vue'

import { Edit, Finished } from '@element-plus/icons'
import { RequestSellerStatusEnum } from '@/utils/constants'
import { UserTypes } from '@/utils/types'
import { isBambooAgency } from '@/utils/agencyUtils'

export default defineComponent({
  components: { RequestSellerDialog, CustomerDialog, Timeline, Edit, Finished },
  props: {
    state: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { error, success } = useNotification()
    const { markAddressValid, checkAddressAgain } = useOrderRepositories()
    const { validateOrderActionSeller } = validateOrderAction()

    const refresh = () => {
      emit('refresh')
    }
    const requestSellerDialog = ref<InstanceType<typeof RequestSellerDialog>>()
    const onClickRequestSeller = (order: any) => {
      requestSellerDialog.value?.toggle(order)
    }

    const customerDialog = ref<InstanceType<typeof CustomerDialog>>()
    const showModalEditCustomer = () => {
      console.log('showModalEditCustomer')
      customerDialog.value?.toggleModal(props.data.customer)
    }

    const getAlowEditCustomerAddress = () => {
      return (
        props.state.order.status === 'Unfulfilled' ||
        props.state.order.status === 'InReview' ||
        props.state.order.status === 'Hold'
      )
    }

    const checkEditCustomer = () => {
      return userInfo?.userType !== UserTypes.SELLER_TEAM || isBambooAgency()
    }

    const showErrorMsg = (errorMsg: string) => {
      if (errorMsg) {
        return errorMsg.replaceAll('\n', '<br>')
      }
    }

    const { params } = useRoute()
    const validAddress = async () => {
      emit('validAddress')
      const { data, status } = await markAddressValid(params.id as string)
      if (status === 200 || status === 201) {
        success('Success')
      } else {
        error(data.message)
      }
    }

    const checkingAddress = async () => {
      emit('checkingAddress')
      const { data, status } = await checkAddressAgain(params.id as string)
      if (status === 200 || status === 201) {
        success('Success')
      } else {
        error(data.message)
      }
    }

    let userInfo:any = undefined
    try {
      userInfo = JSON.parse(localStorage?.getItem('info') || '')
    } catch (e) {
      return error("Can't find user info")
    }

    return {
      refresh,
      RequestSellerStatusEnum,
      requestSellerDialog,
      onClickRequestSeller,
      customerDialog,
      showModalEditCustomer,
      getAlowEditCustomerAddress,
      showErrorMsg,
      validAddress,
      validateOrderActionSeller,
      checkingAddress,
      userInfo,
      UserTypes,
      checkEditCustomer
    }
  },
})
